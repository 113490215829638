import React from 'react';
import './App.css';
import { Container } from '@mui/material';
import { Routes, Route, Outlet, Link , BrowserRouter} from "react-router-dom";

import List from './pages/list';
import Event from './pages/event';

function App() {
  return (
    <BrowserRouter>
    <Container maxWidth="xl" className="app">
      
      <Routes>
        <Route path="/" element={<List />} />
        <Route path="/event" element={<Event />} />
      </Routes>

    </Container>
    </BrowserRouter>
  );
}

export default App;
