import {useEffect, useState} from 'react';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, where,query, orderBy} from "firebase/firestore";
import { firebaseConfig } from "../components/firebase";
import { Container, ImageList, ImageListItem, Avatar } from '@mui/material';

import Header from '../components/header';
import Footer from '../components/footer';

type Event = {
    id: string;
    [key: string]: any;
  }



const List = () => {

    const [events, setEvents] = useState<Event[]>([]); 
    const app = initializeApp(firebaseConfig);
    const firestore = getFirestore(app);

    useEffect ( () => {
        getEvents();
  
    }, []);
  

  const getEvents = async () => {
    const eventsResult = await getDocs(query(collection(firestore, "events"), where("isLive", "==", true), where("isActive", "==", true), orderBy("eventDate", "desc")));

    if(eventsResult.docs.length > 0){
      const tempEvents = eventsResult.docs.map( (doc) => {
        return {id: doc.id, ...doc.data()};
      });
      setEvents(tempEvents);

    }
  }

    return <Container maxWidth="xl">
        <Header />
        { events.length === 0 && <center><h1>There is currently no live setEvents</h1></center>}
        <ImageList cols={1} gap={5}>
    {events.map ( (event) => {
     
      return <ImageListItem key={event.id} className="event-item" >
      
                  <a href={`/event?eventid=${event.id}&host=${event.host}`}>
                  
                  <div className="event-image" >
                    <img src={event.image !== null && event.image !== "" ? event.image : "/appicon_black_1024.png"} alt={event.name}  />
                
                 <div className="event-host">
                    <Avatar className="host-avatar" src={event.hostAvatar !== "" ?  event.hostAvatar : "/appicon_black_1024.png"} alt={event.hostname} sx={{ width: 30, height: 30 }} />
                    
                    <h3>{event.hostname}</h3>
                 </div>
                 </div>
                  <div className="event-description">
                      <h2>{event.name}</h2>
                      <p>{event.description}</p>
                  </div>
                 </a>
            </ImageListItem>
    })}
    
    </ImageList>
        <Footer />   
    </Container>
}

export default List;