import {useEffect, useState, useRef} from 'react';
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../components/firebase";
import { AgoraAppID } from "../components/agora";
import { getFirestore, collection, getDocs, getDoc, doc, where,query, orderBy, DocumentData, onSnapshot, limit } from "firebase/firestore";
import { Container, List, ListItem, ListItemIcon, ListItemText, Avatar } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import AgoraRTC , {ClientConfig, IAgoraRTCRemoteUser } from 'agora-rtc-sdk-ng';

import Header from '../components/header';
import Footer from '../components/footer';

declare global {
    interface Window {
      notifyReady: () => void;
    }
  }

const Event = () => {

    const [host, setHost] = useState("");
    const [videoLayout, setVideoLayout] = useState("grid"); // gird | pip
    const [event, setEvent] = useState<DocumentData | null>(null);
    const [chats, setChats] = useState<DocumentData[]>([]);
    const [liveHosts, setLiveHosts] = useState<IAgoraRTCRemoteUser[]>([]);
    const [joined, setJoined] = useState(false);
    const [hideChat, setHideChat] = useState(false);
    const [eventid, setEventId] = useState("");
    const app = initializeApp(firebaseConfig);
    const firestore = getFirestore(app);
    let agoraEngine: any;

    const chatDiv = useRef();

     // on initial render



     useEffect( () => {
        const settingsFromURL = new URLSearchParams(window.location.search);

     //  if(params.eventid) {
       if(settingsFromURL.get("eventid")) {
           setEventId(settingsFromURL.get("eventid")!);
           getEventInfo(settingsFromURL.get("eventid")!);
   
           if( settingsFromURL.get("layout") ) {
               setVideoLayout(settingsFromURL.get("layout")!);
           }

           if( settingsFromURL.get("host") ) {
               setHost(settingsFromURL.get("host")!);
           }
   
           if( settingsFromURL.get("hideChat") ) {
               const hideChat = settingsFromURL.get("hideChat") == "true" ? true : false;
           
               setHideChat(hideChat);
           }

           initAgora(settingsFromURL.get("eventid")!);
           
       } 

       if(typeof window.notifyReady === "function") {
            window.notifyReady();
       }
      
        // on cleanup
       return () => {
           console.log("exchvnge clean up ");
           leaveChannel();
           
       };
   }, []);

    // on database update
    useEffect( () => {
        if(!hideChat === true && eventid !== "") {
        const chatSub = onSnapshot(query(collection(firestore, "event-chat"), where("eventid", "==", eventid), orderBy("timestamp", "desc"), limit(200)), (snapshot) => {
            if(!snapshot.empty) {

                const docs = snapshot.docs;
                
                const messages: DocumentData[] =[];

                docs.forEach( (chat, index) => {
                    const chatData = chat.data();
                    chatData.id = chat;
                    messages.push(chatData);
                });
                setChats(messages.reverse());
               
                
            } 

        });
        }
    }, [firestore, eventid]);

    useEffect( () => {
        scrollChat();
    })


   const getEventInfo = async (eventid: string) => {
    console.log("exchvnge get event info " + eventid);
    const eventResult = await getDoc(doc(firestore, "events", eventid));
    if(eventResult.exists()) { 
        console.log("exchvnge event data:" , eventResult.data());
        setEvent(eventResult.data());
       
    }
}
  /*   const notifyReady = () => {
        if(window.navigator !== null &&   (typeof window.navigator.notifyReady === 'function')) {
            window.navigator.notifyReady();
        }
    } */

    

    const scrollChat = ()=> {
        const lastIndex = chats.length - 1;
        var ele = document.getElementById("chat-item-" + lastIndex);
        if(ele !== null) {
            ele.scrollIntoView({behavior: "smooth"});
        }
    }

    const initAgora = async (channelName: string) => {
        try {
            
            const clientConfig: ClientConfig = {
                mode: "live",
                codec: "vp8",
                role: "audience"
            };
            agoraEngine= AgoraRTC.createClient( clientConfig );
            
            agoraEngine.on("user-joined", async (user : IAgoraRTCRemoteUser) => {
                setLiveHosts(agoraEngine.remoteUsers);
                console.log("exchvnge remote users joined:" , agoraEngine.remoteUsers);
            });

            agoraEngine.on("user-left", async (user : IAgoraRTCRemoteUser) => {
                setLiveHosts(agoraEngine.remoteUsers);
                console.log("exchvnge remote users left:" , agoraEngine.remoteUsers);
            });

            agoraEngine.on("user-published", async (user : IAgoraRTCRemoteUser , mediaType: String) => {
                await agoraEngine.subscribe(user, mediaType);
               console.log("exchvnge remote users:" , agoraEngine.remoteUsers);
              //  setLiveHosts(agoraEngine.remoteUsers);
                

                if(mediaType === "video") {
                  setupHosts(agoraEngine.remoteUsers);
                }
                if(mediaType === "audio") {
                    user.audioTrack?.play();
                }
            });

            agoraEngine.on("user-unpublished", (user : IAgoraRTCRemoteUser) => {
                agoraEngine.unsubscribe(user);
                setupHosts(agoraEngine.remoteUsers);

            }); 

            const uid = await agoraEngine.join(AgoraAppID, channelName, null, null);
            console.log("exchvnge user " + uid + " joined channel " + channelName + " successfully ");
            console.log("exchvnge remote users:" , agoraEngine.remoteUsers);
           
            setJoined(true);
            setLiveHosts(agoraEngine.remoteUsers);
       
        } catch(err){
            console.error(err);
        }
    }


    const setupHosts = (users : IAgoraRTCRemoteUser [] ) => {
        setLiveHosts(agoraEngine.remoteUsers);
        const container =  document.getElementById("remote-videos-container");
        container!.innerHTML = "";
        users.forEach( (user, index) => {
            const videoContainer = document.createElement("div");
            videoContainer.id = `remote-video-${user.uid}`;
            videoContainer.className = "remote-video";
            if(users.length ===4 || (users.length === 3 && index >= 1) ){
                videoContainer.className = "remote-video half-width";
            }
            container?.appendChild(videoContainer);
            user.videoTrack?.play(`remote-video-${user.uid}`);
         //   user.audioTrack?.play();

        });
    }
    

    const leaveChannel = async () => {
        try {
            agoraEngine && agoraEngine.leave();
        } catch (err){ 
            console.error(err);
        }
    }


    return  <Container maxWidth="xl" id="live-view">
          <Header />
    <h1>{event != null ?  event["name"] : "Exchvnge" }</h1>
    <div id="stream-container">
    <Container maxWidth="md" id="chat-log-container" >
    <List id="chat-log">
        {chats.map( (chat, index) => {
            if(chat.deleted != true) {
           return  <ListItem key={`chat-item-${index}`} id={`chat-item-${index}`} className={chat.isPinned ? "pinned-chat" : "chat"}>
             <ListItemIcon className="chat-avatar"><Avatar alt={chat.sender} src={chat.sender_avatar} sx={{width:20,height:20}}>{chat.sender.substring(0,1)}</Avatar></ListItemIcon>
            <ListItemText >
                <span className="sender ">{chat.sender}</span> <span className="message">{chat.message}</span>
            </ListItemText>
           </ListItem>
            }
        })}
    </List>
    </Container>
    
    <Grid container maxWidth="md" id="remote-videos-container" className={`remote-videos-container host-count-${liveHosts.length}`} >
    
    </Grid>
    </div>
    <Footer />
</Container>
}

export default Event;