

const Footer = () => {
    return <footer className="exchvnge-footer">
    <p><a href="https://link.exchvnge.co/watchapp" target="_blank">
      <img src="/images/appstore.png" alt="Download on iOS App Store" width="120" height="40"/></a>&nbsp;
      <a href="https://link.exchvnge.co/watchapp" target="_blank"><img src="/images/playstore.png" alt="Download on Google Play Store" width="120"  height="40"/>
      </a>
    </p>
    <p className="copyright"> Copyright &copy; <a href="https://www.exchvnge.co" target="_blank">Exchvnge</a> by Livfie Inc {new Date().getFullYear()}.</p>

  </footer>

}

export default Footer;