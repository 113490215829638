
export const firebaseConfig = {
    apiKey: "AIzaSyBPcYPscHd82Fz-QaI2a5oAr5D3PjXxFQ0",
    authDomain: "livfieapp.firebaseapp.com",
    databaseURL: "https://livfieapp.firebaseio.com",
    projectId: "livfieapp",
    storageBucket: "livfieapp.appspot.com",
    messagingSenderId: "432207423329",
    appId: "1:432207423329:web:b211ba5bd5ee0a62530720",
    measurementId: "G-4W3WP1LL17"
  };